<template>
  <m-main-layout>
    演示数据：{{formData}}
    <el-form label-width="200px" style="width: 500px;">
      <el-form-item label="文件上传">
        <file-upload v-model="formData.file"></file-upload>
      </el-form-item>
      <el-form-item label="图片上传">
        <head-img-upload v-model="formData.image"></head-img-upload>
      </el-form-item>
      <el-form-item label="日期选择">
        <push-time v-model="formData.date"></push-time>
      </el-form-item>
      <el-form-item label="角色选择(多选)">
        <role-select v-model="formData.roles" multiple></role-select>
      </el-form-item>
      <el-form-item label="角色选择(单选)">
        <role-select v-model="formData.role" :multiple="false"></role-select>
      </el-form-item>
      <el-form-item label="机构选择(树状结构)">
        <dept-tree-select v-model="formData.deptTree"></dept-tree-select>
      </el-form-item>
      <el-form-item label="图标选择">
        <icon-dialog v-model="formData.icon"></icon-dialog>
      </el-form-item>
      <el-form-item label="年份选择">
        <year-select v-model="formData.year"></year-select>
      </el-form-item>
      <el-form-item label="省市">
        {{formData.area}}
        <province-select style="width: 500px;" v-model="formData.area" @change="onChange" out-data="province,city"></province-select>
      </el-form-item>
      <el-form-item label="省市区">
        {{formData.area2}}
        <province-select style="width: 500px;" v-model="formData.area2" @change="onChange" out-data="province,city,area" type="pca"></province-select>
      </el-form-item>
      <el-form-item label="省市区(只返回区code)">
        {{formData.area3}}
        <province-select style="width: 500px;" v-model="formData.area3" @change="onChange" out-data="area" type="pca"></province-select>
      </el-form-item>
      <el-form-item label="省市区(只返回省code)">
        {{formData.area4}}
        <province-select style="width: 500px;" v-model="formData.area4" @change="onChange" out-data="province" type="pca"></province-select>
      </el-form-item>
      <el-form-item label="日期区间选择">
        <range-time v-model="formData.rangeTime"></range-time>
      </el-form-item>
      <el-form-item label="日期时间选择">
        <create-time v-model="formData.datetime"></create-time>
      </el-form-item>
      <el-form-item label="色彩选择">
        <color-select v-model="formData.color"></color-select>
      </el-form-item>
      <el-form-item label="物资选择">
        <goods-dialog v-model="formData.goods"></goods-dialog>
      </el-form-item>
      <el-form-item label="自定义下拉框">
        <type-select clearable v-model="formData.diyType" :options="[{name:'macbook pro',id:'1'},{name:'mac studio',id:'2'}]"></type-select>
      </el-form-item>
      <el-form-item label="自定义下拉框(调接口)">
        <type-select clearable v-model="formData.urlType" url="/api-admin/api.admin/v1/menus" :data="{page: 1,limit: 10,
queryMode:'page'}" method="get"></type-select>
      </el-form-item>
    </el-form>
  </m-main-layout>
</template>

<script>
export default {
  name: "com",
  data() {
    return {
      formData:{
        file:'',
        image:'',
        date:'',
        roles:[],
        role:'',
        deptTree:'',
        icon:'',
        year:'',
        area:[],
        area2:[],
        area3:'',
        area4:'',
        rangeTime:'',
        datetime:'',
        color:'',
        diyType:'',
        urlType:'',
        goods: [
          { "id": 13, "createTime": null, "createId": null, "createBy": null, "updateTime": null, "updateId": null, "updateBy": null, "deleted": 0, "code": null, "name": null, "img": null, "categoryId": 12, "model": "地方", "brand": 45, "specifications": "", "standard": null, "unit": 3, "firstUnit": null, "firstCoefficient": null, "secondUnit": null, "secondCoefficient": null, "manufacturer": 8, "supplier": 9, "enable": null, "minNum": null, "description": null, "buyNum": null, "surplusCount": 0, "isAuto": null, "categoryName": "fgf", "unitName": "箱", "goodsName": "sdf", "brandName": "阿大法官", "manufacturerName": "ffff11", "supplierName": "123", "firstUnitName": null, "secondUnitName": null }
        ]
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    onChange(e){
      console.log(e);
      // this.$alert('返回结果：'+JSON.stringify(e), '选择了地区', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //
      //   }
      // });
    }
  }
}
</script>
